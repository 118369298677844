<template>
  <div>
    <c-search-box ref="searchbox" :isShowSearch="false" @enter="getList">
      <template slot="search">
        <div class="col-12">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @datachange="getList"/>
        </div>
        <!-- <div class="col-6">
          <c-equip-class
            label="설비유형"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd"
            @datachange="getList">
          </c-equip-class>
        </div> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept label="관리부서" name="managementDepts" v-model="searchParam.managementDepts" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="EQUIP_INSPECTION_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="equipInspectionTypeCd"
            label="점검유형"
            v-model="searchParam.equipInspectionTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            label="단위공정"
            name="subProcessCd"
            v-model="searchParam.subProcessCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="discardFlagItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="discardFlag"
            label="폐기여부"
            v-model="searchParam.discardFlag"
          ></c-select>
        </div>  
        <div class="col-xs-6 col-sm-6 col-md-9 col-lg-9 col-xl-9">
          <c-text
            label="설비명"
            name="equipmentName"
            v-model="searchParam.equipmentName">
          </c-text>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="equipTable"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="equipmentCd"
      :columnSetting="false"
      :isFullScreen="false"
      :filtering="true"
    >
    </c-table>
    <div class="space-bottom-button"></div>
    <!-- 버튼 영역 -->
    <div class="search-box-btn">
      <q-btn-group outline >
        <c-btn label="선택" icon="check" color="teal-5" @btnClicked="select" />
      </q-btn-group>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equip-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          equipmentTypeCd: '',
          grade: '',
          hazardousFlag: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      discardFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비관리번호',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: true,
            // type: 'link',
          },
          // {
          //   name: 'equipmentTypeName',
          //   field: 'equipmentTypeName',
          //   label: '설비유형',
          //   align: 'center',
          //   style: 'width:200px',
          //   sortable: true,
          // },
          // {
          //   name: 'processName',
          //   field: 'processName',
          //   label: '단위공정',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'managementDeptName',
          //   field: 'managementDeptName',
          //   label: '관리부서',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: true,
          // },
        ],
        data: [],
        height: '400px'
      },
      searchParam: {
        plantCd: null,
        equipmentTypeCd: '',
        equipmentName: '',
        equipInspectionTypeCd: null,
        managementDepts: '',
        subProcessCd: '',
        hazardousFlag: '',
        discardFlag: 'N',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 450;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.equipment.list.url;
      // code setting
      if (this.popupParam.equipmentTypeCd) {
        this.searchParam.equipmentTypeCd = this.popupParam.equipmentTypeCd
        this.searchParam.grade = this.popupParam.grade
      }
      if (this.popupParam.subProcessCd) {
        this.searchParam.subProcessCd = this.popupParam.subProcessCd
      }
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      if (this.popupParam.hazardousFlag) {
        this.searchParam.hazardousFlag = this.popupParam.hazardousFlag
      } else {
        this.searchParam.hazardousFlag = 'N'
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['equipTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비를 선택하세요.', // 설비를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '100%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
